.table-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 999;

  .loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 250px;
    padding: 32px;
    background: #62bb46;
    opacity: 0.9;
    border: 1px solid #62bb46;
    border-radius: 4px;
    span {
      z-index: 9999;
      border-width: 5px !important;
    }
    .loader-text {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 1.2px;
    }
  }
  .small-padding{
    padding: 12px;
    top: 42%;
  }
}
