#DataTable {
    position: relative;
  th {
    position: unset !important;
    background-color: #19738d !important;
    color: #fff !important;
  }

  .table-loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    z-index: 999999;
  }
}
