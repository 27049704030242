.import-cont {
  width: 150px;
  min-width: 150px;

  .import-btn {
    width: 90%;
    background-color: #bb1ccc;
    color: #fff;
    text-align: center;
    padding: 8px 0px;
    border-radius: 4px;
    font-size: 18px;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    .import-icon {
      margin-right: 6px;
      font-size: 20px;
    }
  }

  input[type="file"] {
    display: none;
  }
}
#import-dialog {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 100%;
    height: 90%;
    max-width: 100%;
  }

  .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    background-color: #62bb46;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
  }
  .css-ypiqx9-MuiDialogContent-root {
    padding-top: 16px !important;
    position: relative;
  }

  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    border: 1px solid #62bb46;
    color: #62bb46;
    text-align: center;
    padding: 10px 10px 8px 10px;
    font-weight: 500;
    transition: all 0.3s;
    margin-left: 16px !important;

    &:hover {
      background-color: #62bb46;
      color: #fff !important;
      border: 1px solid #62bb46;
    }
  }

  .css-knqc4i-MuiDialogActions-root {
    padding-bottom: 16px !important;
  }
  .primary-button {
    background-color: #62bb46;
    color: #fff;
    border: 1px solid #62bb46;
    transition: all 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }

  .css-qfso29-MuiTypography-root-MuiDialogContentText-root {
    text-align: center;
    margin-bottom: 16px;
    font-size: 22px;
    font-weight: 500;
    color: #62bb46;
  }

  .content-success {
    color: #62bb46 !important;
  }
  .content-error {
    color: #ec5800 !important;
  }
}

.empty-error-width {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-height: calc(100% - 64px) !important;
    max-width: 600px !important;
    width: unset !important;
    height: unset !important;
  }
}
