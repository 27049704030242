.edit-dict-cont {
  .input-container {
    div {
      color: #bb1ccc;
      gap: 0 !important;
    }
    input {
      background-color: #f9faff;
      padding: 6px !important;
      border: 1px solid #999;
    }
  }
  .report-summary-input {
    div {
      margin-bottom: 10px;
    }
  }
  .btn {
    padding: 10px;
    border: none;
    width: 100%;
    max-width: 150px;
    min-width: 150px;
    border-radius: 4px;
    font-weight: 500;
    text-align: center;
    transition: all 0.2s ease-in-out;
  }
  .secondary-btn {
    background-color: transparent;
    border: 1px solid #bb1ccc;
    color: #bb1ccc;
    &:hover {
      background-color: #bb1ccc;
      border: 1px solid #bb1ccc;
      color: #fff;
    }
  }
  .primary-btn {
    background-color: #bb1ccc;
    border: 1px solid #bb1ccc;
    color: #fff;
    &:hover {
      opacity: 0.9;
    }
  }
}
