// #homePage .card {
//   background-color: #fff !important;
// }

// #homePage {
//   background: #fff;
// }

.heading1 {
  font-size: 24px;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0rem;
  color: #bb1ccc;
  text-decoration: underline;
}

#search-form {
  border: 1px solid #999;
  padding: 10px;
  border-radius: 6px;
  background-color: transparent !important;
  width: 400px;
  color: black;
  font-weight: 500;
}
#search-form:focus,
#search-form:active,
#search-form:focus-visible {
  border: 2px solid #bb1ccc !important;
  outline: none;
}
#search-form::placeholder {
  color: #bb1ccc;
  font-size: 14px;
  font-weight: normal !important;
}

.selectParent {
  .custom-select {
    height: 100%;
    border: 1px solid #999 !important;
    background-color: transparent;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
  }
  .custom-select-new {
    height: 100%;
    border: 1px solid #999 !important;
    background-color: transparent;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
    width: 200px;
    color: #bb1ccc;
  }

  .custom-select-new:focus,
  .custom-select-new:active {
    border: 2px solid #bb1ccc !important;
    color: #bb1ccc;
    outline: none;
  }
}

.btn-reset-dict {
  background: #bb1ccc !important;
  border: none !important;
  outline: none !important;
  cursor: pointer !important;
  transition: all 0.3s !important;
  color: #fff !important;
}
.btn-reset-dict:hover {
  opacity: 0.8 !important;
  background: #bb1ccc !important;
}

#DataDirectorymodal-box .modal-box {
  min-width: 90%;
  max-width: 90%;
  padding: 0 !important;
}
.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 0 20px 16px;
  border-radius: 8px;
  box-shadow: 24px;
  border: none;
  width: 850px;
  max-height: 600px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0px;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid black;
  }

  .modal-heading {
    font-size: 30px;
    font-weight: 600;
  }

  .modal-content {
    font-size: 18px;
    font-weight: 500;
    margin-top: 1.5rem;
  }

  .report-heading {
    font-size: 14px;
    font-weight: 700;
  }

  .heading-content {
    font-size: 12px;
    font-weight: 500;
  }

  .points {
    font-size: 12px;
    font-weight: 500;
    margin-left: 20px;
  }

  .main-container {
    .input_field {
      width: 250px;
      padding: 4px 8px;
      /* box-shadow: none; */
    }
    .input_field_reportSummary {
      padding: 10px;
      border-radius: 6px;
      border: 1px solid #999;
      outline: none;
      &:focus,
      &:active,
      &:focus-visible {
        border: 2px solid #bb1ccc !important;
        outline: none;
      }
    }
  }
}
